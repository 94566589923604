//type
import { SearchFormLayout } from '@interfaces/search/HomeForm';

//library
import React, { ReactElement } from 'react';
import { ErrorMessage, Form } from 'formik';

//code

/**
 * Home search form
 *
 * @param {SearchFormLayout} props - The onSubmit function adn search infos
 * @returns {ReactElement} - The form for the home.
 */
const HomeForm: React.FC<SearchFormLayout> = ({
    isAdvancedVisible,
    search,
    advanceSearchInput
}: SearchFormLayout): ReactElement => {
    return (
        <>
            <Form className="flex justify-items-center flex-col">
                <div className="flex justify-items-center mb-10 lg:mb-6" aria-label="search">
                    {search}
                </div>
                <div className="relative ">
                    <div className="text-red-500 text-xs italic absolute -mt-8 lg:-mt-5 pl-6">
                        <ErrorMessage name="postal_district" />
                        <ErrorMessage name="postal_region" />
                    </div>
                </div>
                <div className={`${isAdvancedVisible ? 'open' : 'close'}`}>
                    {isAdvancedVisible && (
                        <span aria-label="advanced-search">{advanceSearchInput}</span>
                    )}
                </div>
            </Form>
        </>
    );
};

export default HomeForm;
