//type
import { BreadCrumbsProps } from '@interfaces/search/helpers/BreadCrumbs';
import { PostalDistrict } from '@interfaces/index';

//library
import React, { ReactElement } from 'react';

/**
 * Create the BreadcCrumb elements for the search
 *
 * @param {BreadCrumbsProps} pros - The props with the breadcrumb data
 * @returns {ReactElement} - The breadcrumbs.
 */
const BreadcCrumbs: React.FC<BreadCrumbsProps> = ({
    query,
    onClick,
    isAdvancedVisible
}: BreadCrumbsProps): ReactElement => (
    <a
        aria-label="breadcrumb"
        onClick={onClick}
        onKeyDown={onClick}
        role="button"
        tabIndex={0}
        className="border-t w-full py-3 px-6 flex items-center flex-wrap">
        <div className="flex-shrink-0 bg-elements hover:shadow-outline text-white rounded-full h-8 w-16 z-10 flex items-center justify-center 3 m-1">
            <svg
                className="block mx-auto"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M19 19L13 13M15 8C15 8.91925 14.8189 9.82951 14.4672 10.6788C14.1154 11.5281 13.5998 12.2997 12.9497 12.9497C12.2997 13.5998 11.5281 14.1154 10.6788 14.4672C9.82951 14.8189 8.91925 15 8 15C7.08075 15 6.1705 14.8189 5.32122 14.4672C4.47194 14.1154 3.70026 13.5998 3.05025 12.9497C2.40024 12.2997 1.88463 11.5281 1.53284 10.6788C1.18106 9.82951 1 8.91925 1 8C1 6.14348 1.7375 4.36301 3.05025 3.05025C4.36301 1.7375 6.14348 1 8 1C9.85652 1 11.637 1.7375 12.9497 3.05025C14.2625 4.36301 15 6.14348 15 8Z"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        </div>
        {query.country &&
            Boolean(query.country.length) &&
            query.country.map((country, index) => (
                <div
                    key={index}
                    className="rounded-full bg-deep-green-100 text-deep-green px-3 py-2 text-xs h-8 mr-3 m-1">
                    <span className="sm:block hidden">{country.label}</span>
                </div>
            ))}
        {query.postal_region &&
            Boolean(query.postal_region.length) &&
            query.postal_region.map((region, index) => (
                <div
                    key={index}
                    className="rounded-full bg-safety-orange-100 text-safety-orange px-3 py-2 text-xs h-8 mr-3 m-1">
                    <span className="sm:block hidden">{region.label}</span>
                </div>
            ))}
        {query.postal_district &&
            Boolean(query.postal_district.length) &&
            query.postal_district.map((district: PostalDistrict, index: number) => (
                <div
                    key={index}
                    className="rounded-full bg-azure-100 text-azure px-3 py-2 text-xs m3-4 h-8 m-1">
                    <span className="sm:block hidden">{district.label}</span>
                </div>
            ))}
        {((query.country && Boolean(query.country.length)) ||
            (query.postal_region && Boolean(query.postal_region.length)) ||
            (query.postal_district && Boolean(query.postal_district.length))) &&
            Boolean(query.pv_capacity_min) &&
            query.pv_capacity_max && (
                <div className="rounded-full bg-lime-100 text-lime px-3 py-2 text-xs m3-4 h-8 m-1">
                    <span className="sm:block hidden">
                        <span>PV Capacity:</span> {query.pv_capacity_min}kW -{' '}
                        {query.pv_capacity_max}kW
                    </span>
                </div>
            )}
        {((query.country && Boolean(query.country.length)) ||
            (query.postal_region && Boolean(query.postal_region.length)) ||
            (query.postal_district && Boolean(query.postal_district.length))) &&
            query.lot_lease &&
            Boolean(query.lot_lease.length) && (
                <div className="rounded-full bg-lavender-100 text-lavender px-3 py-2 text-xs mr-3 h-8 m-1">
                    <span className="sm:block hidden">
                        <span>Lot Lease Term Remaining:</span>{' '}
                        <span className="capitalize">
                            {query.lot_lease &&
                                query.lot_lease.map((lease) => lease.label).join(', ')}{' '}
                        </span>
                    </span>
                </div>
            )}
        {((query.country && Boolean(query.country.length)) ||
            (query.postal_region && Boolean(query.postal_region.length)) ||
            (query.postal_district && Boolean(query.postal_district.length))) &&
            Boolean(query.lot_owner_type?.length) && (
                <div className="rounded-full bg-pale-fushia-100 text-pale-fushia px-3 py-2 text-xs mr-3 h-8 m-1">
                    <span className="sm:block hidden">
                        <span>Lot Ownership Type:</span>{' '}
                        <span className="capitalize">
                            {query.lot_owner_type &&
                                query.lot_owner_type.map((owner) => owner.label).join(', ')}{' '}
                        </span>
                    </span>
                </div>
            )}
        <div
            aria-label="advanced-search"
            className={`ml-auto transform transition-transform ease-in-out duration-500 ${
                isAdvancedVisible ? 'rotate-180' : 'rotate-0'
            }`}>
            <svg
                className="stroke-current"
                width="16"
                height="9"
                viewBox="0 0 16 9"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M15 1L8 8L1 1"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        </div>
    </a>
);

export default BreadcCrumbs;
