//type
import { AdvancedButtonProps } from '@interfaces/search/helpers/AdvancedButton';

//library
import React, { ReactElement } from 'react';

/**
 * Produce a searchBar
 *
 * @param {AdvancedButtonProps} props - The props with the onClick function
 * @returns {ReactElement} - The advanced button.
 */
const AdvancedButton: React.FC<AdvancedButtonProps> = ({
    onClick,
    isAdvancedVisible
}: AdvancedButtonProps): ReactElement => (
    <button
        type="button"
        className="flex items-center focus:outline-none mx-auto text-gray-500 hover:text-elements active:outline-none"
        onClick={onClick}>
        Advanced
        <div
            aria-label="advanced-search"
            className={`ml-3 transform transition-transform ease-in-out duration-500 ${
                isAdvancedVisible ? 'rotate-180' : 'rotate-0'
            }`}>
            <svg
                className="stroke-current"
                width="16"
                height="9"
                viewBox="0 0 16 9"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M15 1L8 8L1 1"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        </div>
    </button>
);

export default AdvancedButton;
