/* eslint-disable import/no-named-as-default-member */
//type
import { MixpanelFunctions } from '@interfaces/helpers/useMixpanel';

//library
import { useEffect, useRef } from 'react';
import { Auth } from 'aws-amplify';
import mixpanel from 'mixpanel-browser';

/**
 * A custom hook to get the user details from cognito and track activity on mixpanel.
 *
 * @returns {MixpanelFunctions} - Object of mixpanel functions
 */
const useMixpanel = (): MixpanelFunctions => {
    const cache = useRef<boolean>(true);

    useEffect(() => {
        /** Get the user info */
        const getUserInfo = async () => {
            const user = await Auth.currentUserInfo();

            await mixpanel.identify(user.attributes.username);
            await mixpanel.people.set({
                $first_name: user.attributes.name,
                $last_name: user.attributes.family_name,
                $email: user.attributes.email,
                $company: user.attributes['custom:company']
            });
            cache.current = false;
        };

        if (cache.current && process.env.NEXT_PUBLIC_MIXPANEL_TOKEN) {
            mixpanel.init(process.env.NEXT_PUBLIC_MIXPANEL_TOKEN, {}, 'mixpanel');
            getUserInfo();
        }
    }, []);

    return {
        track: (name, payload = {}) => {
            if (process.env.NEXT_PUBLIC_MIXPANEL_TOKEN) {
                mixpanel.track(name, payload);
            }
        }
    };
};

export default useMixpanel;
