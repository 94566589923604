//no type

//library
import React, { ReactElement } from 'react';
import { useField } from 'formik';

//code
import TextInput from '@components/search/helpers/TextInput';

/**
 * The pv capacity search input.
 *
 * @returns {ReactElement} - The advanced search.
 */
const PvCapacitySearchInput: React.FC = (): ReactElement => {
    //Get the values from the initalValues
    const [pvCapacityMinfield, pvCapacityMinMeta, pvCapacityMinHelpers] = useField(
        'pv_capacity_min'
    );
    const { value: pvCapacityMinValue } = pvCapacityMinMeta;
    const { setValue: pvCapacityMinSetValue } = pvCapacityMinHelpers;

    const [pvCapacityMaxField, pvCapacityMaxMeta, pvCapacityMaxHelpers] = useField(
        'pv_capacity_max'
    );
    const { value: pvCapacityMaxValue } = pvCapacityMaxMeta;
    const { setValue: pvCapacityMaxSetValue } = pvCapacityMaxHelpers;

    /** Use to render the input wiht an error. */
    const onFocus = (): void => {
        if (!pvCapacityMaxValue) {
            pvCapacityMaxSetValue(9999);
        }

        if (!pvCapacityMinValue) {
            pvCapacityMinSetValue(0);
        }
    };

    return (
        <>
            <label className="my-auto" htmlFor="pv_capacity_min">
                PV Capacity
            </label>
            <TextInput field={pvCapacityMinfield} meta={pvCapacityMinMeta} onFocus={onFocus} />
            <div className="m-auto">
                <div className="border-solid border-b-2 border-gray-500 w-3"></div>
            </div>
            <TextInput field={pvCapacityMaxField} meta={pvCapacityMaxMeta} onFocus={onFocus} />
            <div className="flex justify-start my-auto md:pl-4">
                <div className="text-left text-gray-500">kW</div>
            </div>
        </>
    );
};

export default PvCapacitySearchInput;
