//type
import { OwnershipType } from '@interfaces/search/helpers/OwnershipSelect';

//library
import React, { ReactElement } from 'react';
import { useField } from 'formik';
import Select, { ValueType } from 'react-select';

//code
import styles from '@components/search/helpers/config/selectStylesOwnership';
import listOwnershipType from '@components/helpers/listOwnershipType';

/**
 * Advanced search inputs
 *
 * @returns {ReactElement} - The advanced search.
 */
const OwnershipSelect: React.FC = (): ReactElement => {
    //Get the values from the initalValues
    const [field, meta, helpers] = useField('lot_owner_type');
    const { value } = meta;
    const { setValue } = helpers;

    /**
     * A function to get the selected ownership type
     *
     * @param {ValueType<OwnershipType, boolean>} values - The chosen ownership type
     */
    const onSelectOwnership = (values: ValueType<OwnershipType, true>) => {
        setValue(values || []);
    };

    return (
        <div className="col-span-3">
            <Select
                isMulti
                value={value}
                onChange={onSelectOwnership}
                options={listOwnershipType}
                styles={styles}
                name={field.name}
                aria-label="select-lot-owner-type"
            />
        </div>
    );
};

export default OwnershipSelect;
