//type
import { ListPostalDistricts, ListDistricts } from '@interfaces/helpers/listPostalDistricts';

/** The Postal districts by region in Singapore https://en.wikipedia.org/wiki/Postal_codes_in_Singapore */
export const selectOptions: ListPostalDistricts = [
    {
        value: 'Singapore',
        label: 'Singapore',
        color: '#056608',
        background: 'rgba(4, 99, 7, 0.08)'
    },
    {
        value: 'Central',
        label: 'Region: Central',
        color: '#FF7600',
        background: 'rgba(255, 118, 0, 0.08)',
        country: 'Singapore'
    },
    {
        value: 1,
        label: "1. Raffles Place, Cecil, Marina, People's Parke",
        color: '#3C90E5',
        background: 'rgba(60, 144, 229, 0.08)',
        region: 'Central',
        country: 'Singapore'
    },
    {
        value: 2,
        label: '2. Anson, Tanjong Pagar',
        color: '#3C90E5',
        background: 'rgba(60, 144, 229, 0.08)',
        region: 'Central',
        country: 'Singapore'
    },
    {
        value: 3,
        label: '3. Bukit Merah, Queenstown, Tiong Bahru',
        color: '#3C90E5',
        background: 'rgba(60, 144, 229, 0.08)',
        region: 'Central',
        country: 'Singapore'
    },
    {
        value: 4,
        label: '4. Telok Blangah, Harbourfront',
        color: '#3C90E5',
        background: 'rgba(60, 144, 229, 0.08)',
        region: 'Central',
        country: 'Singapore'
    },
    {
        value: 5,
        label: '5. Pasir Panjang, Hong Leong Garden, Clementi New Town',
        color: '#3C90E5',
        background: 'rgba(60, 144, 229, 0.08)',
        region: 'Central',
        country: 'Singapore'
    },
    {
        value: 6,
        label: '6. High Street, Beach Road (part)',
        color: '#3C90E5',
        background: 'rgba(60, 144, 229, 0.08)',
        region: 'Central',
        country: 'Singapore'
    },
    {
        value: 7,
        label: '7. Middle Road, Golden Mile',
        color: '#3C90E5',
        background: 'rgba(60, 144, 229, 0.08)',
        region: 'Central',
        country: 'Singapore'
    },
    {
        value: 8,
        label: '8. Little India, Farrer Park, Jalan Besar, Lavender',
        color: '#3C90E5',
        background: 'rgba(60, 144, 229, 0.08)',
        region: 'Central',
        country: 'Singapore'
    },
    {
        value: 9,
        label: '9. Orchard, Cairnhill, River Valley',
        color: '#3C90E5',
        background: 'rgba(60, 144, 229, 0.08)',
        region: 'Central',
        country: 'Singapore'
    },
    {
        value: 10,
        label: '10. Ardmore, Bukit Timah, Holland Road, Tanglin',
        color: '#3C90E5',
        background: 'rgba(60, 144, 229, 0.08)',
        region: 'Central',
        country: 'Singapore'
    },
    {
        value: 11,
        label: '11. Watten Estate, Novena, Thomson',
        color: '#3C90E5',
        background: 'rgba(60, 144, 229, 0.08)',
        region: 'Central',
        country: 'Singapore'
    },
    {
        value: 'North East',
        label: 'Region: North East',
        color: '#FF7600',
        background: 'rgba(255, 118, 0, 0.08)',

        country: 'Singapore'
    },
    {
        value: 12,
        label: '12. Balestier, Toa Payoh, Serangoon',
        color: '#3C90E5',
        background: 'rgba(60, 144, 229, 0.08)',
        region: 'North East',
        country: 'Singapore'
    },
    {
        value: 13,
        label: '13. Macpherson, Braddell',
        color: '#3C90E5',
        background: 'rgba(60, 144, 229, 0.08)',
        region: 'North East',
        country: 'Singapore'
    },
    {
        value: 19,
        label: '19. Serangoon Garden, Hougang, Punggol',
        color: '#3C90E5',
        background: 'rgba(60, 144, 229, 0.08)',
        region: 'North East',
        country: 'Singapore'
    },
    {
        value: 20,
        label: '20. Bishan, Ang Mo Kio',
        color: '#3C90E5',
        background: 'rgba(60, 144, 229, 0.08)',
        region: 'North East',
        country: 'Singapore'
    },
    {
        value: 'East',
        label: 'Region: East',
        color: '#FF7600',
        background: 'rgba(255, 118, 0, 0.08)',

        country: 'Singapore'
    },
    {
        value: 14,
        label: '14. Geylang, Eunos, Aljunied',
        color: '#3C90E5',
        background: 'rgba(60, 144, 229, 0.08)',
        region: 'East',
        country: 'Singapore'
    },
    {
        value: 15,
        label: '15. Katong, Joo Chiat, Amber Road',
        color: '#3C90E5',
        background: 'rgba(60, 144, 229, 0.08)',
        region: 'East',
        country: 'Singapore'
    },
    {
        value: 16,
        label: '16. Bedok, Upper East Coast, Eastwood, Kew Drive',
        color: '#3C90E5',
        background: 'rgba(60, 144, 229, 0.08)',
        region: 'East',
        country: 'Singapore'
    },
    {
        value: 17,
        label: '17. Loyang, Changi',
        color: '#3C90E5',
        background: 'rgba(60, 144, 229, 0.08)',
        region: 'East',
        country: 'Singapore'
    },
    {
        value: 18,
        label: '18. Simei, Tampines, Pasir Ris',
        color: '#3C90E5',
        background: 'rgba(60, 144, 229, 0.08)',
        region: 'East',
        country: 'Singapore'
    },

    {
        value: 'West',
        label: 'Region: West',
        color: '#FF7600',
        background: 'rgba(255, 118, 0, 0.08)',

        country: 'Singapore'
    },
    {
        value: 21,
        label: '21. Upper Bukit Timah, Clementi Park, Ulu Pandan',
        color: '#3C90E5',
        background: 'rgba(60, 144, 229, 0.08)',
        region: 'West',
        country: 'Singapore'
    },
    {
        value: 22,
        label: '22. Penjuru, Jurong, Pioneer, Tuas',
        color: '#3C90E5',
        background: 'rgba(60, 144, 229, 0.08)',
        region: 'West',
        country: 'Singapore'
    },
    {
        value: 23,
        label: '23. Hillview, Dairy Farm, Bukit Panjang, Choa Chu Kang',
        color: '#3C90E5',
        background: 'rgba(60, 144, 229, 0.08)',
        region: 'West',
        country: 'Singapore'
    },
    {
        value: 24,
        label: '24. Lim Chu Kang, Tengah',
        color: '#3C90E5',
        background: 'rgba(60, 144, 229, 0.08)',
        region: 'West',
        country: 'Singapore'
    },
    {
        value: 'North',
        label: 'Region: North',
        color: '#FF7600',
        background: 'rgba(255, 118, 0, 0.08)',

        country: 'Singapore'
    },
    {
        value: 25,
        label: '25. Kranji, Woodgrove, Woodlands',
        color: '#3C90E5',
        background: 'rgba(60, 144, 229, 0.08)',
        region: 'North',
        country: 'Singapore'
    },
    {
        value: 26,
        label: '26. Upper Thomson, Springleaf',
        color: '#3C90E5',
        background: 'rgba(60, 144, 229, 0.08)',
        region: 'North',
        country: 'Singapore'
    },
    {
        value: 27,
        label: '27. Yishun, Sembawang, Senoko',
        color: '#3C90E5',
        background: 'rgba(60, 144, 229, 0.08)',
        region: 'North',
        country: 'Singapore'
    },
    {
        value: 28,
        label: '28. Seletar',
        color: '#3C90E5',
        background: 'rgba(60, 144, 229, 0.08)',
        region: 'North',
        country: 'Singapore'
    }
];

export const List: ListDistricts = {
    1: "1. Raffles Place, Cecil, Marina, People's Parke",
    2: '2. Anson, Tanjong Pagar',
    3: '3. Bukit Merah, Queenstown, Tiong Bahru',
    4: '4. Telok Blangah, Harbourfront',
    5: '5. Pasir Panjang, Hong Leong Garden, Clementi New Town',
    6: '6. High Street, Beach Road (part)',
    7: '7. Middle Road, Golden Mile',
    8: '8. Little India, Farrer Park, Jalan Besar, Lavender',
    9: '9. Orchard, Cairnhill, River Valley',
    10: '10. Ardmore, Bukit Timah, Holland Road, Tanglin',
    11: '11. Watten Estate, Novena, Thomson',
    12: '12. Balestier, Toa Payoh, Serangoon',
    13: '13. Macpherson, Braddell',
    14: '14. Geylang, Eunos, Aljunied',
    15: '15. Katong, Joo Chiat, Amber Road',
    16: '16. Bedok, Upper East Coast, Eastwood, Kew Drive',
    17: '17. Loyang, Changi',
    18: '18. Simei, Tampines, Pasir Ris',
    19: '19. Serangoon Garden, Hougang, Punggol',
    20: '20. Bishan, Ang Mo Kio',
    21: '21. Upper Bukit Timah, Clementi Park, Ulu Pandan',
    22: '22. Penjuru, Jurong, Pioneer, Tuas',
    23: '23. Hillview, Dairy Farm, Bukit Panjang, Choa Chu Kang',
    24: '24. Lim Chu Kang, Tengah',
    25: '25. Kranji, Woodgrove, Woodlands',
    26: '26. Upper Thomson, Springleaf',
    27: '27. Yishun, Sembawang, Senoko',
    28: '28. Seletar'
};
