//type
import { LotLeaseType } from '@interfaces/search/helpers/LotLeaseSelect';

//library
import React, { ReactElement } from 'react';
import { useField } from 'formik';
import Select, { ValueType } from 'react-select';

//code
import styles from '@components/search/helpers/config/selectStylesLotLease';
import listLotLeaseType from '@components/helpers/listLotLeaseType';

/**
 * Advanced search inputs
 *
 * @returns {ReactElement} - The advanced search.
 */
const LotLeaseSelect: React.FC = (): ReactElement => {
    //Get the values from the initalValues
    const [field, meta, helpers] = useField('lot_lease');
    const { value } = meta;
    const { setValue } = helpers;

    /**
     * A function to get the selected lot lease
     *
     * @param {ValueType<LotLeaseType, boolean>} values - The chosen ownership type
     */
    const onSelectLotLease = (values: ValueType<LotLeaseType, true>) => {
        setValue(values || []);
    };

    return (
        <div className="col-span-3">
            <Select
                isMulti
                value={value}
                onChange={onSelectLotLease}
                options={listLotLeaseType}
                styles={styles}
                name={field.name}
                aria-label="select-lot-lease"
            />
        </div>
    );
};

export default LotLeaseSelect;
