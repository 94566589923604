//type
import { AdvancedSearchInputProps } from '@interfaces/search/helpers/AdvancedSearchInput';

//library
import React, { ReactElement } from 'react';

//code
import OwnershipSelect from '@components/search/helpers/OwnershipSelect';
import LotLeaseSelect from '@components/search/helpers/LotLeaseSelect';
import PvCapacitySearchInput from '@components/search/helpers/PvCapacityInput';

/**
 * Advanced search inputs
 *
 * @param {AdvancedSearchInputProps} props - The advanced search input props.
 * @returns {ReactElement} - The advanced search.
 */
const AdvancedSearchInput: React.FC<AdvancedSearchInputProps> = ({
    columns
}: AdvancedSearchInputProps): ReactElement => {
    return (
        <div
            className={`text-base font-normal grid pl-6 gap-x-1 gap-y-3 grid-cols-advanced ${
                columns == 2 && 'md:grid-cols-advanced-two-column'
            }`}>
            <PvCapacitySearchInput />

            <label className="my-auto" htmlFor="remaining_tenure_years">
                Lot Lease Term Remaining
            </label>
            <div className="col-span-3">
                <LotLeaseSelect />
            </div>
            <span>&nbsp;</span>

            <label className="my-auto" htmlFor="lot_ownership_type">
                Lot Ownership Type
            </label>
            <div className="col-span-3">
                <OwnershipSelect />
            </div>
        </div>
    );
};

export default AdvancedSearchInput;
