//library
import React, { ReactElement } from 'react';
import { Auth } from 'aws-amplify';
import { useRouter } from 'next/router';

/**
 * Create a Sign out button.
 *
 * @returns {ReactElement} - The sign out button.
 */
const SignOut: React.FC = (): ReactElement => {
    const router = useRouter();
    /** SignOut the user. */
    const logOut = async () => {
        await Auth.signOut();
        router.push('/');
    };

    return (
        <button
            onClick={logOut}
            className="block w-full px-4 py-2 text-left text-sm leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out"
            role="menuitem">
            Sign out
        </button>
    );
};

export default SignOut;
