//type
import { SelectStyleRegion } from '@interfaces/search/helpers/config/selectStylesRegion';

/** The style object for react-select https://react-select.com/styles */
const styles: SelectStyleRegion = {
    control: (styles) => ({
        ...styles,
        borderRadius: '25px 0px 0px 25px',
        background: 'white',
        minHeight: '2.5rem',
        paddingLeft: '0.85rem',
        paddingRight: '3rem',
        boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.2)',
        border: '0 none'
    }),
    multiValue: (styles, { data }) => ({
        ...styles,
        borderRadius: '25px',
        background: data.background,
        color: data.color
    }),
    multiValueLabel: (styles, { data }) => ({
        ...styles,
        color: data.color
    }),
    multiValueRemove: (styles) => {
        return {
            ...styles,
            ':hover': {
                backgroundColor: null,
                color: 'red'
            }
        };
    },
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        let style = {
            ...styles,
            color: isDisabled ? '#808080' : isSelected ? data.color : isFocused ? data.color : '',
            backgroundColor: isDisabled
                ? ''
                : isSelected
                ? data.background
                : isFocused
                ? data.background
                : '',
            cursor: isDisabled ? 'not-allowed' : 'default',
            fontSize: '75%'
        };
        if (!data.region) {
            style = {
                ...style,
                boxSizing: 'border-box',
                cursor: 'default',
                display: 'block',
                fontSize: '80%',
                marginBottom: '0.25em',
                paddingLeft: 12,
                paddingRight: 12,
                textTransform: 'uppercase',
                backgroundColor: data.background
            };
        }

        return style;
    }
};

export default styles;
