//type
import { HeaderProps } from '@interfaces/layout/Header';

//Library
import React, { ReactElement, useRef, useState } from 'react';
import Link from 'next/link';
import Image from 'next/image';
import { useClickAway } from 'react-use';

//code
import SearchForm from '@components/search/SearchForm';
import useCurrentInitial from '@components/layout/helpers/useCurrentUserInitials';
import SignOut from '@components/auth/SignOut';

/**
 * Create the header on the page
 *
 * @param {HeaderProps} props - The search input and the type of header we want
 * @returns Promise<ReactElement> - The header of the page
 */
const Header: React.FC<HeaderProps> = ({ childRef, type, input }: HeaderProps): ReactElement => {
    const initials = useCurrentInitial();
    const [isMenuVisible, setIsMenuVisible] = useState<boolean>(false);

    /** Allow to set the menu state at false when we click outside of it. */
    const dropdownRef = useRef(null);
    useClickAway(dropdownRef, () => {
        setIsMenuVisible(false);
    });

    return (
        <header ref={childRef} className="fixed z-40 bg-white w-screen">
            <nav className="flex items-center px-6 py-4">
                <Link href="/">
                    <a className="w-40 pt-1 ">
                        <Image src="/solarai_logo.png" alt="Solar Ai" width="160" height="26" />
                    </a>
                </Link>
                <div className="flex items-center ml-auto">
                    <Link href="/account/bookmark">
                        <a className="mx-6">
                            <svg
                                className="fill-current stroke-current text-white hover:text-gray-500 text-opacity-0 stroke-2"
                                width="16"
                                height="20"
                                viewBox="0 0 16 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M14.7585 18.8746C14.8653 18.9606 14.9418 18.9877 14.9785 18.9962C14.9805 18.9912 14.9825 18.9859 14.9843 18.9801C14.9921 18.9561 15 18.9186 15 18.8638V1.69774C15 1.42991 14.6737 1 14.1182 1H1.8151C1.30804 1 1 1.38541 1 1.69774V18.8639C1 18.9155 1.00412 18.9545 1.00908 18.9827C1.04603 18.9662 1.1024 18.9342 1.17746 18.8737L1.17833 18.873L6.66332 14.4669C6.66343 14.4668 6.66355 14.4667 6.66366 14.4666C7.04022 14.1634 7.51606 14.0299 7.9586 14.0299C8.39987 14.0299 8.876 14.1627 9.25251 14.4653C9.25302 14.4657 9.25354 14.4661 9.25405 14.4665L14.7562 18.8727L14.7585 18.8746Z"
                                    stroke="#A0AEC0"
                                />
                            </svg>
                        </a>
                    </Link>
                    <div ref={dropdownRef}>
                        <div>
                            <button
                                className="flex text-sm rounded-full focus:outline-none hover:shadow-outline focus:shadow-outline transition duration-150 ease-in-out h-10 w-10 items-center justify-center bg-elements text-white font-medium uppercase"
                                aria-label="User menu"
                                aria-haspopup="true"
                                onClick={() => setIsMenuVisible(!isMenuVisible)}>
                                {initials}
                            </button>
                        </div>
                        <div
                            className={`origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg transition-opacity duration-500 ease-in-out ${
                                isMenuVisible ? 'opacity-100' : 'opacity-0'
                            }`}>
                            {isMenuVisible && (
                                <div
                                    className="py-1 rounded-md bg-white ring-1 ring-black ring-opacity-5"
                                    role="menu"
                                    aria-orientation="vertical"
                                    aria-labelledby="user-menu">
                                    <Link href="/account/profile">
                                        <a
                                            href="#"
                                            className="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out"
                                            role="menuitem">
                                            Your Profile
                                        </a>
                                    </Link>
                                    <SignOut />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </nav>
            {type === 'search' && <SearchForm input={input} type="header" />}
        </header>
    );
};

export default Header;
