//type
import { SelectStyleLotLease } from '@interfaces/search/helpers/config/selectStylesLotLease';

/** The style object for react-select https://react-select.com/styles */
const styles: SelectStyleLotLease = {
    control: (styles) => ({
        ...styles,
        background: 'white',
        minHeight: '2.5rem',
        borderWidth: '1px',
        borderRadius: '0.5rem',
        paddingLeft: '0.25rem',
        borderColor: 'rgba(215, 219, 224, 0.8)'
    }),
    multiValue: (styles) => ({
        ...styles,
        borderRadius: '25px',
        background: 'rgba(121, 65, 239, 0.08)',
        color: '#7941EF'
    }),
    multiValueLabel: (styles) => ({
        ...styles,
        color: '#7941EF'
    }),
    multiValueRemove: (styles) => ({
        ...styles,
        ':hover': {
            backgroundColor: null,
            color: 'red'
        }
    }),
    option: (styles, { isDisabled, isFocused, isSelected }) => ({
        ...styles,
        color: isDisabled ? '#808080' : isSelected ? '#7941EF' : isFocused ? '#7941EF' : '',
        backgroundColor: isDisabled
            ? ''
            : isSelected
            ? 'rgba(121, 65, 239, 0.08)'
            : isFocused
            ? 'rgba(121, 65, 239, 0.08)'
            : '',
        cursor: isDisabled ? 'not-allowed' : 'default',
        fontSize: '75%'
    })
};

export default styles;
