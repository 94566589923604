/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type ProfileInput = {
  email: string,
  name: string,
  family_name: string,
  company: string,
  answer: Array< QuestionInput >,
  xero_contact_id?: string | null,
};

export type QuestionInput = {
  question: string,
  options: Array< string >,
  selected: Array< string >,
  type: string,
};

export type CheckoutInput = {
  email: string,
  name: string,
  family_name: string,
  company: string,
  location: LocationInput,
  price?: number | null,
  xero_contact_id?: string | null,
};

export type LocationInput = {
  id: string,
  pv_capacity: number,
  expiry?: string | null,
  postal_region: Array< string >,
  postal_district_no: Array< number >,
  lot_owner_type: string,
};

export enum SORT {
  ASC = "ASC",
  DESC = "DESC",
}


export enum SORT_FIELD {
  TOTAL_PV_CAPACITY = "TOTAL_PV_CAPACITY",
  REMAINING_TENURE_EXPIRY = "REMAINING_TENURE_EXPIRY",
  LOT_OWNER_TYPE = "LOT_OWNER_TYPE",
  POSTAL_DISTRICT = "POSTAL_DISTRICT",
  POSTAL_REGION = "POSTAL_REGION",
}


export type UpdateProfileMutationVariables = {
  input: ProfileInput,
};

export type UpdateProfileMutation = {
  updateProfile:  {
    __typename: "Profile",
    email: string,
    name: string,
    family_name: string,
    company: string | null,
    xero_contact_id: string | null,
    answer:  Array< {
      __typename: "Question",
      question: string,
      options: Array< string >,
      selected: Array< string >,
      type: string,
    } | null >,
  },
};

export type UpdateBookmarkMutationVariables = {
  input: Array< string | null >,
};

export type UpdateBookmarkMutation = {
  updateBookmark: Array< string | null >,
};

export type CheckoutMutationVariables = {
  input: CheckoutInput,
};

export type CheckoutMutation = {
  checkout:  {
    __typename: "Result",
    id: string,
  },
};

export type GetProfileQuery = {
  getProfile:  {
    __typename: "Profile",
    email: string,
    name: string,
    family_name: string,
    company: string | null,
    answer:  Array< {
      __typename: "Question",
      question: string,
      options: Array< string >,
      selected: Array< string >,
      type: string,
    } | null >,
    xero_contact_id: string | null,
  } | null,
};

export type GetBookmarkQuery = {
  getBookmark: Array< string | null >,
};

export type ListLocationsQueryVariables = {
  country?: Array< string > | null,
  postal_region?: Array< string > | null,
  postal_district?: Array< number > | null,
  pv_capacity_min?: number | null,
  pv_capacity_max?: number | null,
  lot_lease?: Array< string > | null,
  lot_owner_type?: Array< string > | null,
  sort?: SORT | null,
  sort_field?: SORT_FIELD | null,
  from: number,
  size: number,
};

export type ListLocationsQuery = {
  listLocations:  {
    __typename: "ListLocations",
    total: number,
    pv_capacity: number,
    locations:  Array< {
      __typename: "Location",
      id: string,
      pv_capacity: number,
      expiry: string | null,
      postal_region: Array< string >,
      postal_district_no: Array< number >,
      lot_owner_type: string,
      tenure: string | null
    } >,
  },
};

export type ListLocationsByIdsQueryVariables = {
  ids: Array< string >,
  from: number,
  size: number,
  sort?: SORT | null,
  sort_field?: SORT_FIELD | null,
};

export type ListLocationsByIdsQuery = {
  listLocationsByIds:  {
    __typename: "ListLocations",
    total: number,
    pv_capacity: number,
    locations:  Array< {
      __typename: "Location",
      id: string,
      pv_capacity: number,
      expiry: string | null,
      postal_region: Array< string >,
      postal_district_no: Array< number >,
      lot_owner_type: string,
      tenure: string | null
    } >,
  },
};
