//type
import { ListOwnershipType } from '@interfaces/helpers/listOwnershipType';

/** The ownership type */
const listOwnershipType: ListOwnershipType = [
    { value: 'Private', label: 'Private' },
    { value: 'Public', label: 'Public' },
    { value: 'Unknown', label: 'Unknown' }
];

export default listOwnershipType;
