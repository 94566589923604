//no type
import { TextInputProps } from '@interfaces/search/helpers/TextInput';

//library
import React, { ReactElement } from 'react';

/**
 * Render the input text filed for formik with an error.
 *
 * @param {TextInputProps} props - The props of the form field.
 * @returns {ReactElement} - Return the form as we want.
 */
const TextInput: React.FC<TextInputProps> = ({
    field,
    meta,
    onFocus
}: TextInputProps): ReactElement => (
    <div className="flex flex-col my-auto">
        <input
            {...field}
            onFocus={() => onFocus && onFocus()}
            aria-label={field.name}
            type="number"
            className={`h-10 border-1 rounded-lg appearance-none border focus:outline-none pl-1 ${
                meta.error && meta.touched ? 'border-red-500' : 'border-inputBorder'
            } data-hj-allow`}
        />
        <div className="relative" aria-label={`${field.name}_error`}>
            {meta.error && meta.touched && (
                <p className="text-red-500 text-xs italic absolute">{meta.error}</p>
            )}
        </div>
    </div>
);

export default TextInput;
