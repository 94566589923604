//type
import { SelectStyleOwnership } from '@interfaces/search/helpers/config/selectStylesOwnership';

/** The style object for react-select https://react-select.com/styles */
const styles: SelectStyleOwnership = {
    control: (styles) => ({
        ...styles,
        background: 'white',
        minHeight: '2.5rem',
        borderWidth: '1px',
        borderRadius: '0.5rem',
        paddingLeft: '0.25rem',
        borderColor: 'rgba(215, 219, 224, 0.8)'
    }),
    multiValue: (styles) => ({
        ...styles,
        borderRadius: '25px',
        background: '#FEF0FA',
        color: '#F243B7'
    }),
    multiValueLabel: (styles) => ({
        ...styles,
        color: '#F243B7'
    }),
    multiValueRemove: (styles) => ({
        ...styles,
        ':hover': {
            backgroundColor: null,
            color: 'red'
        }
    }),
    option: (styles, { isDisabled, isFocused, isSelected }) => ({
        ...styles,
        color: isDisabled ? '#808080' : isSelected ? '#F243B7' : isFocused ? '#F243B7' : '',
        backgroundColor: isDisabled ? '' : isSelected ? '#FEF0FA' : isFocused ? '#FEF0FA' : '',
        cursor: isDisabled ? 'not-allowed' : 'default',
        fontSize: '75%'
    })
};

export default styles;
