//type
import { SearchFormLayout } from '@interfaces/search/HeaderForm';

//library
import React, { ReactElement } from 'react';
import { ErrorMessage, Form } from 'formik';

/**
 * Search bar form for the header
 *
 * @param {SearchFormLayout} props - The search information and the the onSubmit function
 * @returns {ReactElement} - The header search bar
 */
const HeaderForm: React.FC<SearchFormLayout> = ({
    isAdvancedVisible,
    search,
    advanceSearchInput
}: SearchFormLayout): ReactElement => (
    <Form className={`${isAdvancedVisible ? 'display-block' : 'hidden'}`}>
        <div className="absolute bg-white w-full inset-x-0 border-t pb-8 " aria-label="search">
            <div className="flex justify-items-center m-4 mb-6">{search}</div>
            <div className="relative ">
                <div className="text-red-500 text-xs italic absolute -mt-5 pl-9">
                    <ErrorMessage name="postal_district" />
                </div>
            </div>
            <div className="pl-4">
                {isAdvancedVisible && (
                    <span aria-label="advanced-search">{advanceSearchInput}</span>
                )}
            </div>
        </div>
    </Form>
);

export default HeaderForm;
