//type
import { SearchFormProps, QuerySearch } from '@interfaces/search/SearchForm';
import { SORT, SORT_FIELD } from '@interfaces/graphql/API';
//library
import React, { ReactElement, useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';

//code
import HomeForm from '@components/search/HomeForm';
import HeaderForm from '@components/search/HeaderForm';
import BreadCrumbs from '@components/search/helpers/BreadCrumbs';
import AdvancedButton from '@components/search/helpers/AdvancedButton';
import SearchInput from '@components/search/helpers/SearchInput';
import AdvanceSearchInput from '@components/search/helpers/AdvancedSearchInput';
import useMixpanel from '@components/helpers/useMixpanel';

/**
 * Create the Search Form.
 *
 * @param {SearchFormProps} props - The search information with the type of search form.
 * @returns {ReactElement} Return the search form.
 */
const SearchForm: React.FC<SearchFormProps> = ({ input, type }: SearchFormProps): ReactElement => {
    const [isAdvancedVisible, setIsAdvancedVisible] = useState<boolean>(false);
    const mixpanel = useMixpanel();

    const initialValues: QuerySearch = {
        country: [],
        postal_district: [],
        postal_region: [],
        from: 0,
        size: 10,
        pv_capacity_min: '',
        pv_capacity_max: '',
        lot_lease: [],
        lot_owner_type: []
    };

    if (input) {
        Object.assign(initialValues, { ...input });
    }

    /**
     * Submit the form to the result page
     *
     * @param {QuerySearch} values - The search query criteria
     */
    const onSubmit = (values: QuerySearch) => {
        values.from = 0;
        values.size = 10;
        values.sort = SORT.ASC;
        values.sort_field = SORT_FIELD.POSTAL_DISTRICT;

        const url = [];
        const stats: Record<string, unknown> = {};
        for (const [key, value] of Object.entries(values)) {
            if (value !== '' && value !== null && !Array.isArray(value)) {
                url.push(`${key}=${value}`);
                stats[key] = value;
            }

            if (Array.isArray(value) && value.length) {
                const data = value.map((option) => option.value).join();
                url.push(`${key}=${data}`);
                stats[key] = data.split(',');
            }
        }

        // Track search on mixpanel
        mixpanel.track('searchForm', { ...stats });

        //we can't use router.push because it will not reload the url if we only change the query parameters.
        window.location.assign(`/results?${url.join('&')}`);
    };

    return (
        <>
            {type === 'header' && (
                <BreadCrumbs
                    query={initialValues}
                    onClick={() => setIsAdvancedVisible(!isAdvancedVisible)}
                    isAdvancedVisible={isAdvancedVisible}
                />
            )}
            <Formik
                initialValues={initialValues}
                validationSchema={Yup.object({
                    country: Yup.array(),
                    postal_region: Yup.array(),
                    postal_district: Yup.array().when(['country', 'postal_region'], {
                        is: (country, postal_region) =>
                            (!country || !country.length) &&
                            (!postal_region || !postal_region.length),
                        then: Yup.array().required('A country, region or a district is required.')
                    }),
                    pv_capacity_min: Yup.number().min(0, 'Must be a positive number'),
                    pv_capacity_max: Yup.number()
                        .when('pv_capacity_min', {
                            is: (pv_capacity_min) => pv_capacity_min,
                            then: Yup.number().required('Required').typeError('Required')
                        })
                        .moreThan(Yup.ref('pv_capacity_min'), '> than min'),
                    lot_lease: Yup.array(),
                    lot_owner_type: Yup.array()
                })}
                onSubmit={(values, { setSubmitting }) => {
                    onSubmit(values);
                    setSubmitting(false);
                }}
                enableReinitialize>
                <>
                    {type === 'header' && (
                        <HeaderForm
                            isAdvancedVisible={isAdvancedVisible}
                            search={<SearchInput />}
                            advanceSearchInput={<AdvanceSearchInput columns={2} />}
                        />
                    )}
                    {type === 'home' && (
                        <>
                            <HomeForm
                                isAdvancedVisible={isAdvancedVisible}
                                search={<SearchInput />}
                                advanceSearchInput={<AdvanceSearchInput columns={1} />}
                            />
                            <div className="mt-4">
                                <AdvancedButton
                                    onClick={() => setIsAdvancedVisible(!isAdvancedVisible)}
                                    isAdvancedVisible={isAdvancedVisible}
                                />
                            </div>
                        </>
                    )}
                </>
            </Formik>
        </>
    );
};

export default SearchForm;
