//type
import { ListLotLeaseType } from '@interfaces/helpers/listLotLeaseType';

/** The lot lease term remaining type type */
const listLotLeaseType: ListLotLeaseType = [
    { value: 0, label: 'Unknown' },
    { value: 1, label: '0-5 years' },
    { value: 2, label: '5-10 years' },
    { value: 3, label: '10-15 years' },
    { value: 4, label: '15-20 years' },
    { value: 5, label: '20-25 years' },
    { value: 6, label: '25-50 years' },
    { value: 7, label: '> 50 years' }
];

export default listLotLeaseType;
