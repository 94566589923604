//type
import {
    ValueType,
    PostalDistricts,
    ListPostalDistricts
} from '@interfaces/search/helpers/SearchInput';

//library
import React, { ReactElement, useEffect, useState } from 'react';
import { useField } from 'formik';

//code
import Select from '@components/search/helpers/RegionInput';

/**
 * Create the search bar
 *
 * @returns {ReactElement} The search bar
 */
const SearchInput: React.FC = (): ReactElement => {
    //Get the values from the initalValues
    const [, countryMeta, countryHelpers] = useField('country');
    const { value: countryValue } = countryMeta;
    const { setValue: countrySetValue } = countryHelpers;

    const [, regionMeta, regionHelpers] = useField('postal_region');
    const { value: regionValue } = regionMeta;
    const { setValue: regionSetValue } = regionHelpers;

    const [, districtMeta, districtHelpers] = useField('postal_district');
    const { value: districtValue } = districtMeta;
    const { setValue: districtSetValue } = districtHelpers;

    const [values, setValues] = useState<ListPostalDistricts>([...regionValue, ...districtValue]);

    useEffect(() => {
        if ((regionValue && regionValue.length) || (districtValue && districtValue.length)) {
            setValues([...regionValue, ...districtValue, ...countryValue]);
        }
    }, [regionValue, districtValue, countryValue]);

    /**
     * A function to get the select region or district
     *
     * @param {ValueType<PostalDistricts, boolean>} values - The chosen region or district
     */
    const setValue = (values: ValueType<PostalDistricts, true>) => {
        setValues(values);
        if (!values || !values.length) {
            countrySetValue([]);
            regionSetValue([]);
            districtSetValue([]);
            return;
        }

        const countryValues: (string | null)[] = [];
        const regionValues: (string | null)[] = [];
        const districtValues: (number | null)[] = [];

        for (const obj of values) {
            if (!obj.country && !obj.region) {
                countryValues.push(obj);
            }

            if (obj.country && !obj.region) {
                regionValues.push(obj);
            }

            if (obj.region) {
                districtValues.push(obj);
            }
        }

        countrySetValue(countryValues);
        regionSetValue(regionValues);
        districtSetValue(districtValues);
    };

    return (
        <>
            <Select setValue={setValue} value={values} />
            <button
                aria-label="search-main"
                type="submit"
                className="flex-shrink-0 bg-elements hover:shadow-outline text-white rounded-full min-h-full w-16 -ml-12 z-10 data-hj-allow">
                <svg
                    className="block mx-auto"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M19 19L13 13M15 8C15 8.91925 14.8189 9.82951 14.4672 10.6788C14.1154 11.5281 13.5998 12.2997 12.9497 12.9497C12.2997 13.5998 11.5281 14.1154 10.6788 14.4672C9.82951 14.8189 8.91925 15 8 15C7.08075 15 6.1705 14.8189 5.32122 14.4672C4.47194 14.1154 3.70026 13.5998 3.05025 12.9497C2.40024 12.2997 1.88463 11.5281 1.53284 10.6788C1.18106 9.82951 1 8.91925 1 8C1 6.14348 1.7375 4.36301 3.05025 3.05025C4.36301 1.7375 6.14348 1 8 1C9.85652 1 11.637 1.7375 12.9497 3.05025C14.2625 4.36301 15 6.14348 15 8Z"
                        stroke="white"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            </button>
        </>
    );
};

export default SearchInput;
