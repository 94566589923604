//type
import { RegionInputProps, PostalDistricts } from '@interfaces/search/helpers/RegionInput';

//library
import React, { ReactElement } from 'react';
import Select, { NamedProps } from 'react-select';

//code
import { selectOptions } from '@components/helpers/listPostalDistricts';
import selectStylesRegion from '@components/search/helpers/config/selectStylesRegion';

/**
 * Create the region select
 *
 * @param {RegionInputProps} props - The initialValues and onSubmit function for the select.
 * @returns {ReactElement} The search bar
 */
const RegionInput: React.FC<RegionInputProps> = ({
    value,
    setValue
}: RegionInputProps): ReactElement => {
    /**
     * Disable the option when we select a region or disable the region when we select one of its option
     *
     * @param {Object} option - The displayed option
     * @param {Object} selection - The current selection
     * @returns {boolean} - If the option will be disabled or not
     */
    const disable: NamedProps<PostalDistricts, true>['isOptionDisabled'] = (option, selection) => {
        if (selection.length) {
            for (const selected of selection) {
                //The selected is a country. We disable all the rest.
                if (!selected.country && !selected.region && option.country == selected.value) {
                    return true;
                }

                //The selected is a region. We disable the region and country linked.
                if (
                    selected.country &&
                    !selected.region &&
                    (option.region == selected.value || (!option.region && !option.country))
                ) {
                    return true;
                }

                ///The selected is a district. We disable the region and country linked.
                if (
                    selected.country &&
                    selected.region &&
                    (option.value == selected.region || (!option.region && !option.country))
                ) {
                    return true;
                }
            }
        }

        return false;
    };

    return (
        <div className="relative w-full">
            <Select
                isMulti
                placeholder={'Select a region or a district...'}
                value={value}
                onChange={setValue}
                options={selectOptions}
                hideSelectedOptions={false}
                isOptionDisabled={disable}
                styles={selectStylesRegion}
                name="region-district"
                aria-label="region-district"
            />
        </div>
    );
};

export default RegionInput;
